* {
    font-family: 'Roboto', sans-serif;
}

body, html {
    margin: 0px;
    padding: 0px;
    width: 100%;
    min-height: 100vh;
    -webkit-tap-highlight-color: transparent;
}
  